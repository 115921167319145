exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-about-tsx": () => import("./../../../src/pages/about.tsx" /* webpackChunkName: "component---src-pages-about-tsx" */),
  "component---src-pages-agro-tsx": () => import("./../../../src/pages/agro.tsx" /* webpackChunkName: "component---src-pages-agro-tsx" */),
  "component---src-pages-bank-tsx": () => import("./../../../src/pages/bank.tsx" /* webpackChunkName: "component---src-pages-bank-tsx" */),
  "component---src-pages-department-tsx": () => import("./../../../src/pages/department.tsx" /* webpackChunkName: "component---src-pages-department-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-invest-tsx": () => import("./../../../src/pages/invest.tsx" /* webpackChunkName: "component---src-pages-invest-tsx" */),
  "component---src-pages-lot-tsx": () => import("./../../../src/pages/lot.tsx" /* webpackChunkName: "component---src-pages-lot-tsx" */),
  "component---src-pages-politicas-tsx": () => import("./../../../src/pages/politicas.tsx" /* webpackChunkName: "component---src-pages-politicas-tsx" */),
  "component---src-pages-reclamacion-tsx": () => import("./../../../src/pages/reclamacion.tsx" /* webpackChunkName: "component---src-pages-reclamacion-tsx" */),
  "component---src-pages-sell-tsx": () => import("./../../../src/pages/sell.tsx" /* webpackChunkName: "component---src-pages-sell-tsx" */),
  "component---src-pages-terms-tsx": () => import("./../../../src/pages/terms.tsx" /* webpackChunkName: "component---src-pages-terms-tsx" */)
}

